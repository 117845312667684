// types.ts
export enum LogLevel {
  NONE,
  ERROR,
  WARN,
  INFO,
  DEBUG,
}

export type LogMessage = string | Error;
export type OptionalParams = unknown[];

// Ensure proper typing for the window object
declare global {
  interface Window {
    FORCE_DEBUG_MODE?: boolean;
  }
}

// logger.ts
export class Logger {
  // Private static fields with proper typing
  private static currentLogLevel = LogLevel.DEBUG;
  private static readonly isLocalDevelopment =
    process.env.REACT_APP_ENV === "development";

  /**
   * Sets the current log level
   * @param level The log level to set
   */
  static setLogLevel(level: LogLevel): void {
    this.currentLogLevel = level;
  }

  /**
   * Gets the current log level
   * @returns The current log level
   */
  static getLogLevel(): LogLevel {
    return this.currentLogLevel;
  }

  /**
   * Determines if logging should occur based on the environment and log level
   * @param level The log level to check
   * @returns Boolean indicating if logging should occur
   */
  private static shouldLog(level: LogLevel): boolean {
    return Boolean(
      window?.FORCE_DEBUG_MODE ||
        (this.isLocalDevelopment && this.currentLogLevel >= level)
    );
  }

  /**
   * Gets the current timestamp in ISO format
   * @returns Formatted timestamp string
   */
  private static getTimestamp(): string {
    return new Date().toISOString();
  }

  /**
   * Formats the log message with timestamp and level
   * @param level The log level
   * @param message The message to format
   * @returns Formatted log message
   */
  private static formatMessage(level: string, message: LogMessage): string {
    const timestamp = this.getTimestamp();
    const formattedMessage =
      message instanceof Error ? message.message : message;
    return `[${level}] [${timestamp}] ${formattedMessage}`;
  }

  /**
   * Logs an info message
   * @param message The message to log
   * @param optionalParams Additional parameters to log
   */
  static info(message: LogMessage, ...optionalParams: OptionalParams): void {
    if (this.shouldLog(LogLevel.INFO)) {
      console.info(
        this.formatMessage("INFO", message),
        ...(message instanceof Error
          ? [message, ...optionalParams]
          : optionalParams)
      );
    }
  }

  /**
   * Logs a warning message
   * @param message The message to log
   * @param optionalParams Additional parameters to log
   */
  static warn(message: LogMessage, ...optionalParams: OptionalParams): void {
    if (this.shouldLog(LogLevel.WARN)) {
      console.warn(
        this.formatMessage("WARN", message),
        ...(message instanceof Error
          ? [message, ...optionalParams]
          : optionalParams)
      );
    }
  }

  /**
   * Logs an error message
   * @param message The message to log
   * @param optionalParams Additional parameters to log
   */
  static error(message: LogMessage, ...optionalParams: OptionalParams): void {
    if (this.shouldLog(LogLevel.ERROR)) {
      console.error(
        this.formatMessage("ERROR", message),
        ...(message instanceof Error
          ? [message, ...optionalParams]
          : optionalParams)
      );
    }
  }

  /**
   * Logs a debug message
   * @param message The message to log
   * @param optionalParams Additional parameters to log
   */
  static debug(message: LogMessage, ...optionalParams: OptionalParams): void {
    if (this.shouldLog(LogLevel.DEBUG)) {
      console.debug(
        this.formatMessage("DEBUG", message),
        ...(message instanceof Error
          ? [message, ...optionalParams]
          : optionalParams)
      );
    }
  }
}

// Example usage:
const createLogger = () => {
  // Set up logger configuration
  if (process.env.NODE_ENV === "production") {
    Logger.setLogLevel(LogLevel.ERROR);
  }

  return Logger;
};

export default createLogger();
