import db, {
  dbFlight,
  dbTraining,
  dbDirect,
  flightSummary,
} from "../indexDB/Database";
import {
  FlightSearchData,
  vendorData,
  categoryData,
  TrainingData,
} from "../types/Types";
import {
  SetEmailAction,
  SetPasswordAction,
  SetRememberMeAction,
  SetAuthTokenAction,
  ClearAuthTokenAction,
  ShowHidePasswordAction,
  LogoutAction,
  Loader,
  SubscriptionData,
  RoleData,
  RolesData,
  LoginResponse,
  CurrentUser,
  clearCurrentUserAction,
  AirportData,
  Airports,
  FlightSearch,
  clearFlightSearchInterface,
  SetFlightType,
  MyFlightInterface,
  ToasterMessageIndertface,
  ToasterMessageData,
  myFlightData,
  VendorInterface,
  ReturnedFlight,
  NavigationToggleAction,
  UpdateCredits,
  CreditSummary,
  FlightFilter,
  AirlineFilter,
  CategoryInterface,
  TrainingInterface,
  HistoryReportFilterInterface,
  ListQueryParams,
  myHotelData,
  MyHotelInterface,
} from "../interface/DataInterface";
// Action types enum
export enum LoginActionTypes {
  SET_EMAIL = "SET_EMAIL",
  SET_PASSWORD = "SET_PASSWORD",
  SET_REMEMBER_ME = "SET_REMEMBER_ME",
  SET_AUTH_TOKEN = "SET_AUTH_TOKEN",
  CLEAR_AUTH_TOKEN = "CLEAR_AUTH_TOKEN",
  SHOW_HIDE_PASSWORD = "SHOW_HIDE_PASSWORD",
  LOGOUT = "LOGOUT",
  LOADER = "LOADER",
  BOOKED_TYPE = "BOOKED_TYPE",
  SUBSCRIPTION = "SUBSCRIPTION",
  ROLE = "ROLE",
  CURRENT_USER = "CURRENT_USER",
  CLEAR_CURRENT_USER = "CLEAR_CURRENT_USER",
  SET_AIPORTS = "SET_AIPORTS",
  CLEAR_FLIGHT_SEARCH = "CLEAR_FLIGHT_SEARCH",
  SET_FLIGHT_SEARCH = "SET_FLIGHT_SEARCH",
  SET_FLIGHT_TYPE = "SET_FLIGHT_TYPE",
  SET_MY_FLIGHT = "SET_MY_FLIGHT",
  SET_TOASTER = "SET_TOASTER",
  SET_VENDOR = "SET_VENDOR",
  SET_RETURN_FLIGHT_SEARCH = "SET_RETURN_FLIGHT_SEARCH",
  TOGGLE_NAVIGATION_BAR = "TOGGLE_NAVIGATION_BAR",
  UPDATE_USER_CREDITS = "UPDATE_USER_CREDITS",
  UPDATE_CREDIT_SUMMARY = "UPDATE_CREDIT_SUMMARY",
  SET_FLIGHT_FILTER = "SET_FLIGHT_FILTER",
  SET_AIRLINE_FILTER = "SET_AIRLINE_FILTER",
  SET_CATEGORIES = "SET_CATEGORIES",
  SET_TRAININGS = "SET_TRAININGS",
  SET_DATA = "SET_DATA",
  SET_FLIGHT_REVIEW = "SET_FLIGHT_REVIEW",
  SET_ADDONS_AMOUNT = "SET_ADDONS_AMOUNT",
  SET_ADDONS_SSR = "SET_ADDONS_SSR",
  SET_HISTORY_REPORT_FILTER = "SET_HISTORY_REPORT_FILTER",
  SET_MY_HOTEL = "SET_MY_HOTEL",
}

// Define a union type for all possible actions
export type LoginAction =
  | SetEmailAction
  | SetPasswordAction
  | SetRememberMeAction
  | SetAuthTokenAction
  | ClearAuthTokenAction
  | ShowHidePasswordAction
  | LogoutAction
  | Loader
  | SubscriptionData
  | RolesData
  | CurrentUser
  | clearCurrentUserAction
  | Airports
  | clearFlightSearchInterface
  | FlightSearch
  | SetFlightType
  | MyFlightInterface
  | ToasterMessageIndertface
  | VendorInterface
  | ReturnedFlight
  | NavigationToggleAction
  | UpdateCredits
  | CreditSummary
  | FlightFilter
  | AirlineFilter
  | CategoryInterface
  | TrainingInterface
  | HistoryReportFilterInterface
  | MyHotelInterface;

export const updateUserCredits = (data: any): UpdateCredits => ({
  type: LoginActionTypes.UPDATE_USER_CREDITS,
  payload: data,
});

export const updateCreditSummary = (data: any): CreditSummary => ({
  type: LoginActionTypes.UPDATE_CREDIT_SUMMARY,
  payload: data,
});

// Action creators
export const setEmail = (email: string): SetEmailAction => ({
  type: LoginActionTypes.SET_EMAIL,
  payload: email,
});

export const setPassword = (password: string): SetPasswordAction => ({
  type: LoginActionTypes.SET_PASSWORD,
  payload: password,
});

export const setRememberMe = (remember_me: boolean): SetRememberMeAction => ({
  type: LoginActionTypes.SET_REMEMBER_ME,
  payload: remember_me,
});

export const setAuthToken = (token: string): SetAuthTokenAction => {
  // Save the token to IndexedDB using Dexie

  db.customTables["authToken"].add({ token: token });

  return {
    type: LoginActionTypes.SET_AUTH_TOKEN,
    payload: token,
  };
};

export const setSearchFlightResult = (data: any) => ({
  type: LoginActionTypes.SET_DATA,
  payload: data,
});

export const setAddOnsAmount = (data: any) => ({
  type: LoginActionTypes.SET_ADDONS_AMOUNT,
  payload: data,
});

export const setAddOnSSR = (data: any) => ({
  type: LoginActionTypes.SET_ADDONS_SSR,
  payload: data,
});

export const setFlightReview = (data: any) => ({
  type: LoginActionTypes.SET_FLIGHT_REVIEW,
  payload: data,
});

export const clearAuthToken = (): ClearAuthTokenAction => ({
  type: LoginActionTypes.CLEAR_AUTH_TOKEN,
});

export const showHidePassword = (
  showPassword: boolean
): ShowHidePasswordAction => ({
  type: LoginActionTypes.SHOW_HIDE_PASSWORD,
  payload: showPassword,
});

export const toggleNavigationBar = (
  isOpen: boolean
): NavigationToggleAction => ({
  type: LoginActionTypes.TOGGLE_NAVIGATION_BAR,
  payload: isOpen,
});

// Action creator for logout
export const logout = (): LogoutAction => ({
  type: LoginActionTypes.LOGOUT,
});

export const clearCurrentUser = (): clearCurrentUserAction => ({
  type: LoginActionTypes.CLEAR_CURRENT_USER,
});

export const setLoader = (show: boolean): Loader => ({
  type: LoginActionTypes.LOADER,
  payload: show,
});

export const setRole = (data: RoleData): RolesData => ({
  type: LoginActionTypes.ROLE,
  payload: data,
});

export const setCurrentUser = (data: LoginResponse): CurrentUser => {
  db.clearTable("currentUser");
  db.customTables["currentUser"].add(data);
  return {
    type: LoginActionTypes.CURRENT_USER,
    payload: data,
  };
};

export const setAirports = (data: AirportData[]): Airports => {
  db.clearTable("airport");
  db.customTables["airport"].bulkAdd(data);
  return {
    type: LoginActionTypes.SET_AIPORTS,
    payload: data,
  };
};

export const clearFlightSearch = (): clearFlightSearchInterface => ({
  type: LoginActionTypes.CLEAR_FLIGHT_SEARCH,
});

export const setFlightSearch = (data: FlightSearchData): FlightSearch => {
  db.clearTable("flightSearch");
  db.clearTable("flightDetails");
  dbDirect.clearTable("onwardFlight");
  flightSummary.clearTable("onwardCheapest");
  flightSummary.clearTable("onwardShortest");
  if (data?.flight) {
    db.customTables["flightSearch"].bulkAdd(data.flight);
  }

  if (data?.onward) {
    dbDirect.customTables["onwardFlight"].bulkAdd(data.onward);
  }

  if (data?.onwardCheapest) {
    flightSummary.customTables["onwardCheapest"].add(data.onwardCheapest);
  }

  if (data?.onwardShortest) {
    flightSummary.customTables["onwardShortest"].add(data.onwardShortest);
  }

  if (data?.details) {
    db.customTables["flightDetails"].bulkAdd(data.details);
  }

  return {
    type: LoginActionTypes.SET_FLIGHT_SEARCH,
    payload: data,
  };
};

export const setReturnFlightSearch = (
  data: FlightSearchData
): ReturnedFlight => {
  dbFlight.clearTable("returnFlightSearch");
  dbFlight.clearTable("returnFlightDetails");
  dbDirect.clearTable("returnFlight");
  flightSummary.clearTable("returnCheapest");
  flightSummary.clearTable("returnShortest");
  if (data?.flight) {
    dbFlight.customTables["returnFlightSearch"].bulkAdd(data.flight);
  }

  if (data?.return) {
    dbDirect.customTables["returnFlight"].bulkAdd(data.return);
  }

  if (data?.returnCheapest) {
    flightSummary.customTables["returnCheapest"].add(data.returnCheapest);
  }

  if (data?.returnShortest) {
    flightSummary.customTables["returnShortest"].add(data.returnShortest);
  }

  if (data?.details) {
    dbFlight.customTables["returnFlightDetails"].bulkAdd(data.details);
  }

  return {
    type: LoginActionTypes.SET_RETURN_FLIGHT_SEARCH,
    payload: data,
  };
};

export const setFlightFilter = (data: any): FlightFilter => {
  return {
    type: LoginActionTypes.SET_FLIGHT_FILTER,
    payload: data,
  };
};

export const setAirlineFilter = (data: any): AirlineFilter => {
  return {
    type: LoginActionTypes.SET_AIRLINE_FILTER,
    payload: data,
  };
};

export const setFlightType = (isOneWay: boolean): SetFlightType => ({
  type: LoginActionTypes.SET_FLIGHT_TYPE,
  isOneWay: isOneWay,
});

export const setMyFlight = (data: myFlightData): MyFlightInterface => ({
  type: LoginActionTypes.SET_MY_FLIGHT,
  payload: data,
});

export const setToasterMessage = (
  data: ToasterMessageData
): ToasterMessageIndertface => ({
  type: LoginActionTypes.SET_TOASTER,
  payload: data,
});

export const setVendor = (data: vendorData[]): VendorInterface => {
  db.clearTable("vendor");
  db.customTables["vendor"].bulkAdd(data);
  return {
    type: LoginActionTypes.SET_VENDOR,
    payload: data,
  };
};

export const setCategories = (data: categoryData[]): CategoryInterface => {
  dbTraining.clearTable("categories");
  dbTraining.customTables["categories"].bulkAdd(data);
  return {
    type: LoginActionTypes.SET_CATEGORIES,
    payload: data,
  };
};

export const setTrainings = (data: TrainingData[]): TrainingInterface => {
  dbTraining.clearTable("training");
  dbTraining.customTables["training"].bulkAdd(data);
  return {
    type: LoginActionTypes.SET_TRAININGS,
    payload: data,
  };
};

export const setHistoryReportFilter = (
  query: ListQueryParams
): HistoryReportFilterInterface => ({
  type: LoginActionTypes.SET_HISTORY_REPORT_FILTER,
  payload: query,
});

export const setMyHotel = (data: myHotelData): MyHotelInterface => ({
  type: LoginActionTypes.SET_MY_HOTEL,
  payload: data,
});
