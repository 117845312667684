import { Helmet } from "react-helmet";

const Header: React.FC = () => {
  return (
    <Helmet>
      <script src="https://cdn.mywanderlinx.com/assets/js/layout.js"></script>
      <link
        href="https://cdn.mywanderlinx.com/assets/css/bootstrap.min.css"
        rel="stylesheet"
        type="text/css"
      />
      <link
        href="https://cdn.mywanderlinx.com/assets/css/icons.min.css"
        rel="stylesheet"
        type="text/css"
      />
      <link
        href="https://cdn.mywanderlinx.com/assets/css/app.min.css"
        rel="stylesheet"
        type="text/css"
      />
      <link
        href="https://cdn.mywanderlinx.com/assets/css/custom.min.css"
        rel="stylesheet"
        type="text/css"
      />

      <link
        href="https://cdn.mywanderlinx.com/assets/css/flyitinerary.css"
        rel="stylesheet"
        type="text/css"
      />
    </Helmet>
  );
};

export default Header;
