import { LoginActionTypes, LoginAction } from "./actions";
import db, { dbFlight, flightSummary } from "../indexDB/Database";
import {
  FormData,
  hideShowData,
  authData,
  isLoading,
  RoleData,
  City,
  Plan,
  Company,
  LoginResponse,
  AirportData,
  flightTypeData,
  myFlightData,
  ToasterMessageData,
  isOpen,
  ListQueryParams,
  HistoryReportFilterInterface,
  myHotelData,
} from "../interface/DataInterface";
import {
  FlightSearchData,
  vendorData,
  categoryData,
  TrainingData,
  SubTraining,
  SelectData,
} from "../types/Types";

const initialState: FormData = {
  email: "",
  password: "",
  remember_me: false,
};

const hideShowState: hideShowData = {
  showPassword: false,
};

const loader: isLoading = {
  show: false,
};

const toggle: isOpen = {
  isOpen: false,
};

const flightType: flightTypeData = {
  isOneWay: false,
};

export const toasterInitialState: ToasterMessageData = {
  isShow: false,
  header: "",
  type: "",
  message: "",
  delay: 5000,
};

const authInitialState: authData = {
  authToken: "",
};

const myFlightInitialState: myFlightData = {
  from: "",
  fromCity: "",
  arrival: "",
  arrivalCity: "",
  arrivalTime: "",
  departureTime: "",
  duration: "",
  isSearching: false,
  isFlightSelected: false,
  selectedFlight: "",
  returnFlight: "",
  gTotal: "",
  bFare: "",
  tFare: "",
  itineraryReturn: false,
  resendItineraryId: 0,
  isChangeFlight: false,
  mealAmount: 0,
  baggageAmount: 0,
  insufficientAmount: 0,
  intlRoundTrip: false,
  isDomestic: false,
};

const roleInitialState: RoleData = {
  name: "",
};

const city: City = {
  id: 1,
  name: "",
};

const plan: Plan = {
  id: 1,
  name: "",
};

const company: Company = {
  id: 1,
  name: "",
  page: "",
  logo: "",
  dti: "",
  zipcode: "",
  address: "",
  country: "",
  country_id: 0,
  itinerary_color: "",
  city: city,
  plan: plan,
};

export const InitialStateCurrentUser: LoginResponse = {
  id: 1,
  fname: "",
  lname: "",
  profile: "",
  dob: "",
  dobDefault: "",
  age: 1,
  cnumber: "",
  email: "",
  company: company,
  created_at: "",
  updated_at: "",
  deleted_at: "",
  percentage_complete: 1,
};

export const airportInitialState: AirportData[] = [
  {
    city: "",
    name: "",
    iataCode: "",
    detail: "",
  },
];

const dataInitinialState = {
  data: "",
};

const myHotelInitialState: myHotelData = {
  selectedHotelDetails: {
    main_image: "",
    extra_images: [],
    name: "",
    address: "",
    description: "",
    facilities_and_services: [],
    room_types: {
      room_name: "",
      room_rate_per_night: 0,
      meal_plan: "",
      is_refundable: "",
      cancellation_policy: "",
    },
    reviews: {
      rtg: 0,
      numRv: "",
    },
    rating_logo: "",
  },
  resendHotelBookingId: null,
};

export const SearchFlightResult = (state = dataInitinialState, action: any) => {
  switch (action.type) {
    case LoginActionTypes.SET_DATA:
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
};

export const SetAddOnsAmount = (state = dataInitinialState, action: any) => {
  switch (action.type) {
    case LoginActionTypes.SET_ADDONS_AMOUNT:
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
};

export const SetAddOnSSR = (state = dataInitinialState, action: any) => {
  switch (action.type) {
    case LoginActionTypes.SET_ADDONS_SSR:
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
};

export const SetFlightReview = (state = dataInitinialState, action: any) => {
  switch (action.type) {
    case LoginActionTypes.SET_FLIGHT_REVIEW:
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
};

export const authReducer = (state = authInitialState, action: LoginAction) => {
  switch (action.type) {
    case LoginActionTypes.SET_AUTH_TOKEN:
      return { ...state, authToken: action.payload };
    case LoginActionTypes.CLEAR_AUTH_TOKEN:
      db.clearTable("authToken");
      return { ...state, authToken: "" };
    case LoginActionTypes.LOGOUT:
      localStorage.clear();
      // Clear the auth token from IndexedDB
      db.clearAllTables();
      return authInitialState;
    default:
      return state;
  }
};

export const loginReducer = (state = initialState, action: LoginAction) => {
  switch (action.type) {
    case LoginActionTypes.SET_EMAIL:
      return { ...state, email: action.payload };
    case LoginActionTypes.SET_PASSWORD:
      return { ...state, password: action.payload };
    case LoginActionTypes.SET_REMEMBER_ME:
      return { ...state, remember_me: action.payload };
    default:
      return state;
  }
};

export const passwordReducer = (state = hideShowState, action: LoginAction) => {
  switch (action.type) {
    case LoginActionTypes.SHOW_HIDE_PASSWORD:
      return {
        ...state,
        showPassword: action.payload,
      };
    default:
      return state;
  }
};

export const flightFilter = (state = {}, action: LoginAction) => {
  switch (action.type) {
    case LoginActionTypes.SET_FLIGHT_FILTER:
      return {
        ...state,
        filter: action.payload,
      };
    default:
      return state;
  }
};

export const airlineFilter = (state = {}, action: LoginAction) => {
  switch (action.type) {
    case LoginActionTypes.SET_AIRLINE_FILTER:
      return {
        ...state,
        airline: action.payload,
      };
    default:
      return state;
  }
};

export const toggleNavBar = (state = toggle, action: LoginAction) => {
  switch (action.type) {
    case LoginActionTypes.TOGGLE_NAVIGATION_BAR:
      return {
        ...state,
        isOpen: action.payload,
      };
    default:
      return state;
  }
};

export const loaderReducer = (state = loader, action: LoginAction) => {
  switch (action.type) {
    case LoginActionTypes.LOADER:
      return {
        ...state,
        show: action.payload,
      };
    default:
      return state;
  }
};

export const RoleReducer = (state = roleInitialState, action: LoginAction) => {
  switch (action.type) {
    case LoginActionTypes.ROLE:
      return {
        ...state,
        payload: action.payload,
      };
    default:
      return state;
  }
};

export const currentUserReducer = (
  state = InitialStateCurrentUser,
  action: LoginAction
) => {
  switch (action.type) {
    case LoginActionTypes.CURRENT_USER:
      return {
        ...state,
        ...action.payload,
      };
    case LoginActionTypes.CLEAR_CURRENT_USER:
      db.clearTable("currentUser");
      return state;
    case LoginActionTypes.UPDATE_USER_CREDITS:
      db.customTables["currentUser"]
        .orderBy("id")
        .last()
        .then((user) => {
          user.credits = action.payload.credits;
          user.use_credits = action.payload.use_credits;
          user.total_credits = action.payload.total_credits;
          db.customTables["currentUser"].put(user);
        });
      return {
        ...state,
        credits: action.payload.credits,
        use_credits: action.payload.use_credits,
        total_credits: action.payload.total_credits,
        deleted_credits: action.payload.deleted_credits,
        total_commissions: action.payload.total_commissions,
        total_royalty: action.payload.total_royalty,
        total_earned: action.payload.total_earned,
        total_transfer: action.payload.total_transfer,
        total_balance: action.payload.total_balance,
      };
    case LoginActionTypes.UPDATE_CREDIT_SUMMARY:
      db.customTables["currentUser"]
        .orderBy("id")
        .last()
        .then((user) => {
          user.credit_summary = action.payload.credit_summary;
          db.customTables["currentUser"].put(user);
        });
      return {
        ...state,
        credit_summary: action.payload.credit_summary,
      };
    default:
      return state;
  }
};

export const Airports = (state = airportInitialState, action: LoginAction) => {
  switch (action.type) {
    case LoginActionTypes.SET_AIPORTS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export const flightSearchInitialState: FlightSearchData[] = [
  {
    details: [],
    flight: [],
  },
];

export const FlightSearch = (
  state = flightSearchInitialState,
  action: LoginAction
) => {
  switch (action.type) {
    case LoginActionTypes.SET_FLIGHT_SEARCH:
      return {
        ...state,
        ...action.payload,
      };
    case LoginActionTypes.CLEAR_FLIGHT_SEARCH:
      db.clearTable("flightSearch");
      db.clearTable("flightDetails");
      dbFlight.clearTable("returnFlightSearch");
      dbFlight.clearTable("returnFlightDetails");
      flightSummary.clearTable("onwardCheapest");
      flightSummary.clearTable("onwardShortest");
      flightSummary.clearTable("returnCheapest");
      flightSummary.clearTable("returnShortest");
      return state;
    default:
      return state;
  }
};

export const ReturnFlight = (
  state = flightSearchInitialState,
  action: LoginAction
) => {
  switch (action.type) {
    case LoginActionTypes.SET_RETURN_FLIGHT_SEARCH:
      return {
        ...state,
        ...action.payload,
      };
    case LoginActionTypes.CLEAR_FLIGHT_SEARCH:
      db.clearTable("flightSearch");
      db.clearTable("flightDetails");
      dbFlight.clearTable("returnFlightSearch");
      dbFlight.clearTable("returnFlightDetails");
      flightSummary.clearTable("onwardCheapest");
      flightSummary.clearTable("onwardShortest");
      flightSummary.clearTable("returnCheapest");
      flightSummary.clearTable("returnShortest");
      return state;
    default:
      return state;
  }
};

export const FlightType = (state = flightType, action: LoginAction) => {
  switch (action.type) {
    case LoginActionTypes.SET_FLIGHT_TYPE:
      return {
        ...state,
        isOneWay: action.isOneWay,
      };
    default:
      return state;
  }
};

export const MyFlight = (state = myFlightInitialState, action: LoginAction) => {
  switch (action.type) {
    case LoginActionTypes.SET_MY_FLIGHT:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export const ToasterMsg = (
  state = toasterInitialState,
  action: LoginAction
) => {
  switch (action.type) {
    case LoginActionTypes.SET_TOASTER:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export const vendorInitialState: vendorData[] = [
  {
    id: 1,
    name: "",
    site: "",
  },
];

export const BookVendor = (state = vendorInitialState, action: LoginAction) => {
  switch (action.type) {
    case LoginActionTypes.SET_VENDOR:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export const categoryInitialState: categoryData[] = [
  {
    id: 1,
    name: "",
    training_count: 0,
  },
];

export const Categories = (
  state = categoryInitialState,
  action: LoginAction
) => {
  switch (action.type) {
    case LoginActionTypes.SET_CATEGORIES:
      return {
        ...state,
        payload: action.payload,
      };
    default:
      return state;
  }
};

const subTraining: SubTraining[] = [
  {
    id: 0,
    title: "",
    status: "",
    is_main: 0,
    category: "",
    duration: "",
    language: "",
    parent_id: 0,
    video_name: "",
    category_id: 0,
    description: "",
    thumbnail_url: "",
    short_description: "",
  },
];

const categories: SelectData[] = [
  {
    id: 1,
    name: "",
  },
];

export const trainingInitialState: TrainingData[] = [
  {
    id: 1,
    name: "",
    category_id: 0,
    category: "",
    duration_left: "",
    progress: "",
    title: "",
    short_description: "",
    is_main: 0,
    parent_id: 0,
    categories: categories,
    status: "",
    description: "",
    thumbnail_url: "",
    video_url: 0,
    language: 0,
    duration: "",
    created_at: "",
    formatted_date: "",
    sub_training: subTraining,
    sub_training_count: 0,
  },
];

export const Trainings = (
  state = trainingInitialState,
  action: LoginAction
) => {
  switch (action.type) {
    case LoginActionTypes.SET_TRAININGS:
      return {
        ...state,
        payload: action.payload,
      };
    default:
      return state;
  }
};

const historyReportInitialState: ListQueryParams = {
  from: "",
};

export const HistoryReportFilter = (
  state = historyReportInitialState,
  action: LoginAction
) => {
  if (action.type === LoginActionTypes.SET_HISTORY_REPORT_FILTER) {
    return {
      ...state,
      ...(action as HistoryReportFilterInterface).payload,
    };
  }
  return state;
};

export const MyHotel = (state = myHotelInitialState, action: LoginAction) => {
  switch (action.type) {
    case LoginActionTypes.SET_MY_HOTEL:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
