import { Middleware } from 'redux';
import { LoginActionTypes } from '../redux/actions';
import db from '../indexDB/Database';

const authMiddleware: Middleware = (store) => (next) => (action) => {
  if (action.type === LoginActionTypes.SET_AUTH_TOKEN) {
    const { authToken } = store.getState().auth;
    if (authToken) {
      db.customTables['authToken'].put({ token: authToken });
    }
  }
  return next(action);
};

export default authMiddleware;
