import Dexie from "dexie";
import { Logger } from "../utils/Logger";

class Database extends Dexie {
  customTables: { [key: string]: Dexie.Table<any, number> } = {};

  constructor(
    databaseName: string,
    schema: { [key: string]: string },
    version: number = 1
  ) {
    super(databaseName);
    this.version(version).stores(schema);

    for (const tableName in schema) {
      if (schema.hasOwnProperty(tableName)) {
        this.customTables[tableName] = this.table(tableName);
      }
    }
  }

  clearTable(tableName: string) {
    if (this.customTables[tableName]) {
      Logger.info(`Table cleared ${tableName}.`);
      return this.customTables[tableName].clear();
    } else {
      Logger.warn(`Table ${tableName} does not exist.`);
    }
  }

  clearAllTables() {
    const databaseNames = [
      "WanderlinxDatabase",
      "WanderlinxFlight",
      "WanderlinxTraining",
      "WanderlinxDirectBooking",
      "WanderlinxFlightSummary",
    ];

    for (const tableName in this.customTables) {
      this.clearTable(tableName);
    }

    for (const dbName of databaseNames) {
      Dexie.delete(dbName);
    }

    localStorage.clear();

    if ("caches" in window) {
      caches
        .keys()
        .then((cacheNames) => {
          return Promise.all(cacheNames.map((cache) => caches.delete(cache)));
        })
        .then(() => {
          console.log("Cache storage cleared.");
        })
        .catch(Logger.error);
    }
  }

  doesTableExist(tableName: string) {
    return this.customTables.hasOwnProperty(tableName);
  }
}

// Define schemas
const mainSchema = {
  currentUser:
    "++id, fname, lname, profile, dob, dobDefault, age, cnumber, email, is_reset_password, credits, total_credits, use_credits, total_purchase, company, created_at, updated_at, deleted_at, percentage_complete",
  authToken: "++id, token",
  airport: "++id, city, name, iataCode, detail",
  flightSearch:
    "++id, interval, reference, seatsLeft, carrierCode, carrierName, carrierLogo, departureTime, originalDepTime, originalArrTime, departureCity, departureCode, departureDetails, departureAirport, departureTerminal, arrivalTerminal , arrivalTime, arrivalCity, arrivalCode, arrivalAirport, arrivalDetails, flightNo, stops , stopDetails, flightDetailsIds , duration, flyTime, baggage",
  flightDetails:
    "++id, interval, reference, seatsLeft, carrierCode, carrierName, carrierLogo, departureTime, originalDepTime, originalArrTime, departureCity, departureCode, departureDetails, departureAirport, departureTerminal, arrivalTerminal , arrivalTime, arrivalCity, arrivalCode, arrivalAirport, arrivalDetails, flightNo, stops , stopDetails, flightDetailsIds , duration, flyTime, baggage",
  vendor: "++id, name, site",
};

const trainingSchema = {
  categories: "++id, name, training_count",
  training:
    "++id, name, category_id, title, is_main, parent_id, status, categories, description, short_description, duration_left, progress, thumbnail_url, video_url, language, duration, created_at, formatted_date",
};

const flightSchema = {
  returnFlightSearch:
    "++id, interval, reference, seatsLeft, carrierCode, carrierName, carrierLogo, departureTime, originalDepTime, originalArrTime, departureCity, departureCode, departureDetails, departureAirport, departureTerminal, arrivalTerminal , arrivalTime, arrivalCity, arrivalCode, arrivalAirport, arrivalDetails, flightNo, stops , stopDetails, flightDetailsIds , duration, flyTime, baggage",
  returnFlightDetails:
    "++id, interval, reference, seatsLeft, carrierCode, carrierName, carrierLogo, departureTime, originalDepTime, originalArrTime, departureCity, departureCode, departureDetails, departureAirport, departureTerminal, arrivalTerminal , arrivalTime, arrivalCity, arrivalCode, arrivalAirport, arrivalDetails, flightNo, stops , stopDetails, flightDetailsIds , duration, flyTime, baggage",
};

const directSchema = {
  onwardFlight:
    "key, fareAmount, paxFares, flightKey, fareKey, seatsLeft, flights, flightSummary, direct_booking",
  returnFlight:
    "key, fareAmount, paxFares, flightKey, fareKey, seatsLeft, flights, flightSummary, direct_booking",
};

const summarySchema = {
  onwardCheapest:
    "key, fareAmount, paxFares, flightKey, fareKey, seatsLeft, flights, flightSummary, direct_booking",
  onwardShortest:
    "key, fareAmount, paxFares, flightKey, fareKey, seatsLeft, flights, flightSummary, direct_booking",
  returnCheapest:
    "key, fareAmount, paxFares, flightKey, fareKey, seatsLeft, flights, flightSummary, direct_booking",
  returnShortest:
    "key, fareAmount, paxFares, flightKey, fareKey, seatsLeft, flights, flightSummary, direct_booking",
};

// Create database instances
const db = new Database("WanderlinxDatabase", mainSchema, 1);
export const dbFlight = new Database("WanderlinxFlight", flightSchema, 1);
export const dbTraining = new Database("WanderlinxTraining", trainingSchema, 1);
export const dbDirect = new Database(
  "WanderlinxDirectBooking",
  directSchema,
  1
);
export const flightSummary = new Database(
  "WanderlinxFlightSummary",
  summarySchema,
  1
);

export default db;
