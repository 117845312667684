// Library Imports
import { configureStore } from "@reduxjs/toolkit";
// import thunk from 'redux-thunk';

// Custom Middleware
import authMiddleware from "../middleware/authMiddleware";

// Reducers
import {
  loginReducer,
  authReducer,
  passwordReducer,
  loaderReducer,
  RoleReducer,
  currentUserReducer,
  InitialStateCurrentUser,
  Airports,
  FlightSearch,
  FlightType,
  MyFlight,
  ToasterMsg,
  BookVendor,
  ReturnFlight,
  toggleNavBar,
  flightFilter,
  airlineFilter,
  Categories,
  Trainings,
  SearchFlightResult,
  SetFlightReview,
  SetAddOnsAmount,
  SetAddOnSSR,
  HistoryReportFilter,
  MyHotel,
} from "./reducer";

// Other Imports
import db, {
  dbFlight,
  dbTraining,
  dbDirect,
  flightSummary,
} from "../indexDB/Database";
// import { LoginActionTypes } from '../redux/actions';

// Combine the reducers
const rootReducer = {
  loginPayload: loginReducer,
  auth: authReducer,
  hideShow: passwordReducer,
  currentUser: currentUserReducer,
  airports: Airports,
  flights: FlightSearch,
  oCheapestFlight: FlightSearch,
  oShortestFlight: FlightSearch,
  returnFlights: ReturnFlight,
  rCheapestFlight: ReturnFlight,
  rShortestFlight: ReturnFlight,
  vendor: BookVendor,
  categories: Categories,
  trainings: Trainings,
  loader: loaderReducer,
  role: RoleReducer,
  flightType: FlightType,
  myFlight: MyFlight,
  toaster: ToasterMsg,
  toggle: toggleNavBar,
  flightFilter: flightFilter,
  airlineFilter: airlineFilter,
  booking: SearchFlightResult,
  flightReview: SetFlightReview,
  addOnsAmount: SetAddOnsAmount,
  addOnsSSR: SetAddOnSSR,
  historyReportFilter: HistoryReportFilter,
  myHotel: MyHotel,
};

// Async initialization function for the store
const initializeStore = async () => {
  const authToken = await loadAuthToken();
  const user = await loadCurrentUser();
  const airtportData = await loadAirport();
  const flightSearchResult = await loadFlightSearch();
  const returnFlightsResults = await loadReturnFlightSearch();
  const vendorList = await loadVendor();
  const categoryList = await loadCategories();
  const trainingList = await loadTrainings();
  const rCheapestFlight = await getReturnCheapestFlight();
  const rShortestFlight = await getReturnShortestFlight();
  const oCheapestFlight = await getOnwardCheapestFlight();
  const oShortestFlight = await getOnwardShortestFlight();

  // Create the initial state using the retrieved auth token and user data
  const initialState = {
    loginPayload: { email: "", password: "", remember_me: false },
    auth: { authToken },
    hideShow: { showPassword: false },
    currentUser: user,
    airports: airtportData,
    flights: flightSearchResult,
    returnFlights: returnFlightsResults,
    vendor: vendorList,
    categories: categoryList,
    trainings: trainingList,
    rCheapestFlight: rCheapestFlight,
    rShortestFlight: rShortestFlight,
    oCheapestFlight: oCheapestFlight,
    oShortestFlight: oShortestFlight,
  };

  // Create the store with combined reducers and apply the middleware
  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false,
      }).concat(authMiddleware),
    preloadedState: initialState,
  });

  return store;
};

// Retrieve the auth token from IndexedDB on application load
export const loadAuthToken = async (): Promise<string> => {
  try {
    const tokenRecord = await db.customTables["authToken"].orderBy("id").last();
    return tokenRecord?.token || "";
  } catch (error) {
    console.error("Failed to load auth token:", error);
    return "";
  }
};

// Retrieve the current user from IndexedDB on application load
export const loadCurrentUser = async () => {
  try {
    const user = await db.customTables["currentUser"].orderBy("id").last();
    return user;
  } catch (error) {
    console.error("Failed to load current user:", error);
    return InitialStateCurrentUser;
  }
};

export const loadAirport = async () => {
  try {
    const airports = await db.customTables["airport"].toArray();
    return airports;
  } catch (error) {
    console.error("Failed to load airport: ", error);
    return [];
  }
};

export const loadFlightSearch = async () => {
  try {
    var flights = await db.customTables["flightSearch"].toArray();
    if (flights.length > 0) {
      return flights.sort((a, b) => a.fareAmount - b.fareAmount);
    }
    // Call for direct booking
    flights = await dbDirect.customTables["onwardFlight"].toArray();

    if (flights.length > 0) {
      return flights.sort((a, b) => a.fareAmount - b.fareAmount);
    }

    return [];
  } catch (error) {
    console.error("Failed to load flight search: ", error);
    return [];
  }
};

export const getOnwardCheapestFlight = async () => {
  try {
    // Call for direct booking
    const flights = await flightSummary.customTables[
      "onwardCheapest"
    ].toArray();

    if (flights.length > 0) {
      return flights;
    }

    return [];
  } catch (error) {
    console.error("Failed to load Cheapest Flight: ", error);
    return [];
  }
};

export const getOnwardShortestFlight = async () => {
  try {
    // Call for direct booking
    const flights = await flightSummary.customTables[
      "onwardShortest"
    ].toArray();

    if (flights.length > 0) {
      return flights;
    }

    return [];
  } catch (error) {
    console.error("Failed to load Shortest Flight: ", error);
    return [];
  }
};

export const getReturnCheapestFlight = async () => {
  try {
    // Call for direct booking
    const flights = await flightSummary.customTables[
      "returnCheapest"
    ].toArray();

    if (flights.length > 0) {
      return flights;
    }

    return [];
  } catch (error) {
    console.error("Failed to load Cheapest Flight: ", error);
    return [];
  }
};

export const getReturnShortestFlight = async () => {
  try {
    // Call for direct booking
    const flights = await flightSummary.customTables[
      "returnShortest"
    ].toArray();

    if (flights.length > 0) {
      return flights;
    }

    return [];
  } catch (error) {
    console.error("Failed to load Shortest Flight: ", error);
    return [];
  }
};

export const loadReturnFlightSearch = async () => {
  try {
    var flights = await dbFlight.customTables["returnFlightSearch"].toArray();
    if (flights.length > 0) {
      return flights.sort((a, b) => a.fareAmount - b.fareAmount);
    }
    // Call for direct booking
    flights = await dbDirect.customTables["returnFlight"].toArray();

    if (flights.length > 0) {
      return flights.sort((a, b) => a.fareAmount - b.fareAmount);
    }

    return [];
  } catch (error) {
    console.error("Failed to load return flight search: ", error);
    return [];
  }
};

export const loadVendor = async () => {
  try {
    const vendor = await db.customTables["vendor"].toArray();
    return vendor;
  } catch (error) {
    console.error("Failed to load Vendor: ", error);
    return [];
  }
};

export const loadCategories = async () => {
  try {
    const category = await dbTraining.customTables["categories"].toArray();
    return category;
  } catch (error) {
    console.error("Failed to load Categories: ", error);
    return [];
  }
};

export const loadTrainings = async () => {
  try {
    const training = await dbTraining.customTables["training"].toArray();
    return training;
  } catch (error) {
    console.error("Failed to load Categories: ", error);
    return [];
  }
};

// Export the async initialization function to be used in the application
export default initializeStore;
