import { Spinner } from "react-bootstrap";

export const SpinnerLoader = () => {
  return (
    <div className="text-center pt-5 pb-5">
      <Spinner
        animation="border"
        role="status"
        className="color-0ab39c"
      ></Spinner>
    </div>
  );
};
