import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const PageNotFound: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Redirect after a short delay to give users context (optional)
    const timer = setTimeout(() => {
      navigate("/account-setting");
    }, 1000); // 1000 milliseconds delay

    // Cleanup the timer if the component unmounts before the redirection
    return () => clearTimeout(timer);
  }, [navigate]); // Added navigate to dependency array for completeness

  // Render a message or any other fallback UI
  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ height: "100vh" }}
    >
      <div>
        <h1>Page Not Found</h1>
        <p>Redirecting to settings...</p>
      </div>
    </div>
  );
};

export default PageNotFound;
