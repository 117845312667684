import React, { memo, Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./component/includes/Header";
import PageNotFound from "./component/pages/PageNotFound";
import { SpinnerLoader } from "./component/utils/Loading";

// Lazy loading components for code splitting
const Affiliate = lazy(() => import("./component/pages/Affiliate"));
const CreateETicket = lazy(() => import("./component/pages/CreateETicket"));
const FlightChange = lazy(() => import("./component/pages/FlightChange"));
const DirectBooking = lazy(() => import("./component/pages/DirectBooking"));
const BusinessSetting = lazy(() => import("./component/pages/BusinessSetting"));
const AgentList = lazy(() => import("./component/pages/AgentList"));
const ActivationCode = lazy(() => import("./component/pages/ActivationCode"));
const PassengerDetails = lazy(
  () => import("./component/pages/PassengerDetails")
);
const HistoryReport = lazy(() => import("./component/pages/HistoryReport"));
const DirectBookingHistory = lazy(
  () => import("./component/pages/DirectBookingHistory")
);
const VerifyData = lazy(() => import("./component/pages/VerifyData"));
const ProviderList = lazy(() => import("./component/pages/ProviderList"));
const Logout = lazy(() => import("./component/pages/Logout"));
const BillingHistory = lazy(() => import("./component/pages/BillingHistory"));
const AddFund = lazy(() => import("./component/pages/AddFund"));
const PaymentHistory = lazy(() => import("./component/pages/PaymentHistory"));
const CreateTraining = lazy(() => import("./component/pages/CreateTraining"));
const TrainingList = lazy(() => import("./component/pages/TrainingList"));
const MainCourse = lazy(() => import("./component/pages/MainCourse"));
const TermsCondition = lazy(() => import("./component/pages/TermsCondition"));
const BookingPassengerDetails = lazy(
  () => import("./component/pages/BookingPassengerDetails")
);
const AddBaggage = lazy(() => import("./component/pages/AddBaggage"));
const CalendarFlight = lazy(() => import("./component/pages/CalendarFlight"));
const CreateEVoucher = lazy(() => import("./component/pages/CreateEVoucher"));
const EVoucherHistoryReport = lazy(
  () => import("./component/pages/EVoucherHistoryReport")
);

const App: React.FC = () => {
  const MemoizedVerifyData = memo(VerifyData);

  return (
    <Router>
      <Header />
      <Suspense fallback={<SpinnerLoader />}>
        <Routes>
          <Route path="/" element={<MemoizedVerifyData />} />
          <Route path="/activation-code" element={<ActivationCode />} />
          <Route path="/affiliate-dashboard" element={<Affiliate />} />
          <Route path="/terms-condition" element={<TermsCondition />} />
          <Route path="/flight-booking" element={<DirectBooking />} />
          <Route path="/e-ticket-generator" element={<CreateETicket />} />
          <Route path="/e-voucher-generator" element={<CreateEVoucher />} />
          <Route path="/flight-change" element={<FlightChange />} />
          <Route path="/add-baggage" element={<AddBaggage />} />
          <Route path="/account-setting" element={<BusinessSetting />} />
          <Route path="/agent-list" element={<AgentList />} />
          <Route path="/passenger-details" element={<PassengerDetails />} />
          <Route
            path="/booking-details"
            element={<BookingPassengerDetails />}
          />
          <Route path="/e-ticket-history-report" element={<HistoryReport />} />
          <Route
            path="/e-voucher-history-report"
            element={<EVoucherHistoryReport />}
          />
          <Route path="/calendar-flight" element={<CalendarFlight />} />
          <Route
            path="/booking-history-report"
            element={<DirectBookingHistory />}
          />
          <Route path="/provider-list" element={<ProviderList />} />
          <Route path="/verify/:token" element={<MemoizedVerifyData />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/billing-history" element={<BillingHistory />} />
          <Route path="/travel-fund" element={<AddFund />} />
          <Route path="/payment-history" element={<PaymentHistory />} />
          <Route path="/create-training" element={<CreateTraining />} />
          <Route path="/training/edit/:id" element={<CreateTraining />} />
          <Route path="/trainings" element={<TrainingList />} />
          <Route path="/training/main-course" element={<MainCourse />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Suspense>
    </Router>
  );
};

export default App;
